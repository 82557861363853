import React from 'react';
import { FaInstagram } from 'react-icons/fa';
import { FiYoutube } from 'react-icons/fi';
import { TbBrandTiktok } from 'react-icons/tb';
import classes from './footer.module.scss';

const Footer = () => {
	return (
		<footer className={classes.footer}>
			<div className='_cont_limit'>
				<div className={classes.body}>
					<div className={classes.item}>
						<a href='https://www.zeta.kz/about_us/'>
							<h4>О нас</h4>
						</a>
					</div>
					<div className={classes.item}>
						<a href='https://www.zeta.kz/our_stores/'>
							<h4>Магазины</h4>
						</a>
					</div>

					<div className={classes.item}>
						<div className={classes.contacts}>
							<a href='mailto:e.nureeva@zeta.kz'>e.nureeva@zeta.kz</a>
						</div>
					</div>
					<div className={`${classes.item} ${classes.item_icon}`}>
						<div className={classes.icon}>
							<a href='https://www.instagram.com/zeta.kz/'>
								<FaInstagram />
							</a>
						</div>
						<div className={classes.icon}>
							<a href='https://www.tiktok.com/@zeta_kz'>
								<TbBrandTiktok />
							</a>
						</div>
						<div className={classes.icon}>
							<a href='https://www.youtube.com/@zeta_kz'>
								<FiYoutube />
							</a>
						</div>
					</div>

					<div className={classes.item}>
						<a href='https://t.me/Gloomycatt'>devoloped by NasYrov</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
